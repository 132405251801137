import { Link } from "gatsby"
import React from "react"
import Logo from "../images/logo.png"

export default class Navbar extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      affix: false,
      collapsed: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    this._isMounted = true
    window.addEventListener("scroll", this.handleScroll, true)
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  handleScroll() {
    if (!this._isMounted) return
    if (window.scrollY > 1) {
      this.setState({ affix: true })
    } else {
      this.setState({ affix: false })
    }
  }
  render() {
    return (
      <nav
        className={
          "navbar navbar-horizontal navbar-expand-lg navbar-dark fixed-top" +
          (this.state.affix ? " affix bg-primary-dark shadow-sm" : "")
        }
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={Logo}
              className={"mt-n1"}
              alt={"ebrand logo"}
              width={"150"}
              height={"45"}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-primary"
            aria-controls="navbar-primary"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className={"navbar-toggler-icon"} />
          </button>
          <div className="collapse navbar-collapse" id="navbar-primary">
            <ul className="navbar-nav ml-lg-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/palvelut">
                  Web-sovellukset
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tuotteet">
                  Tuotteet
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blog">
                  Ajankohtaista
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/yhteystiedot">
                  Yhteystiedot
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
