import React from "react"
import PropTypes from "prop-types"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faCalculator,
  faMoneyBillWave,
  faTachometerAlt,
  faUsers,
  faShoppingBasket,
  faTimes,
  faHourglassStart,
  faHashtag,
} from "@fortawesome/free-solid-svg-icons"

// CSS
//import "purpose-ui-kit/assets/css/purpose-blue-light.css";
import "./purpose-blue-light.css"
import "purpose-ui-kit/assets/libs/animate.css/animate.min.css"
import "./global.css"

// JS
import "purpose-ui-kit/assets/js/purpose"

import Navbar from "./navbar"
import Footer from "./footer"

const Layout = ({ children }) => {
  library.add(
    fab,
    faCalculator,
    faUsers,
    faMoneyBillWave,
    faTachometerAlt,
    faShoppingBasket,
    faTimes,
    faHourglassStart,
    faHashtag
  )
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
