import { Link } from "gatsby"
import React from "react"
import Logo from "../images/logo.png"
import FacebookIcon from "../images/svg/icons/facebook-square-brands.svg"
import InstagramIcon from "../images/svg/icons/instagram-brands.svg"
import LinkedinIcon from "../images/svg/icons/linkedin-brands.svg"
import TwitterIcon from "../images/svg/icons/twitter-square-brands.svg"
import Waves from "../images/svg/wave-2.svg"
import Bold from "./Bold"

const Footer = () => (
  <div className={"footer-wrap pt-2"}>
    <img
      src={Waves}
      className="w-100 position-absolute"
      alt={"Waves svg"}
      style={{ transform: "translateY(-90%)" }}
    />
    <footer className="footer footer-dark bg-primary-dark">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mb-5 mb-lg-0">
            <img
              src={Logo}
              alt="ebrand logo"
              className="mb-4"
              width={"180"}
              height={"54"}
            />
            <p className="text-sm">
              Helposti lähestyttävä ohjelmistokehityksen kumppani.
            </p>
            <p className="text-sm">
              <a
                href="https://www.dropbox.com/s/8fn8yx3m1rd3xyc/Tietosuojakaytannot_ebrand_sivut.pdf?dl=1"
                style={{ color: "hsla(0,0%,100%,.8)" }}
                target="_blank"
              >
                Tietosuojaseloste
              </a>
            </p>
            <p className="text-sm">
              <a
                href="https://zeckit.com/selvitys/FI/2189517-3?section=quickcheck"
                rel="noopener"
                target="_blank"
              >
                <img
                  src="https://kampanja.vastuugroup.fi/hubfs/Luotettava-Kumppani-logo_verkkosivuille-1.jpg"
                  width={"180"}
                  height={"84"}
                  alt="Tarkista kelpoisuutemme -> Luotettava Kumppani"
                />
              </a>
            </p>
          </div>
          <div className="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
            <h6 className="heading mb-3">Navigointi</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/">Etusivu</Link>
              </li>
              <li>
                <Link to="/palvelut">Verkkopalvelut</Link>
              </li>
              <li>
                <Link to="/tuotteet">Tuotteet</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
            <h6 className="heading mb-3">&nbsp;</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/blog">Ajankohtaista</Link>
              </li>
              <li>
                <Link to="/yhteystiedot">Yhteystiedot</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-4 mb-5 mb-lg-0">
            <h6 className="heading mb-3">Tuotteet</h6>
            <ul className="list-unstyled">
              <li>
                <Link to={"/some-ja-nuoret"}>Some ja nuoret</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row align-items-center justify-content-md-between py-4 mt-4 delimiter-top">
          <div className="col-md-6">
            <div className="copyright text-sm font-weight-bold text-center text-md-left">
              &copy;
              <a
                href="https://ebrand.fi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Bold>ebrand.fi</Bold>
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.facebook.com/ebrand.fi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={FacebookIcon}
                    width="25"
                    height="25"
                    alt={"Facebook logo"}
                  />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="http://instagram.com/ebrandfi/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={InstagramIcon}
                    width="25"
                    height="25"
                    alt={"Instagram logo"}
                  />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.linkedin.com/company/ebrand-suomi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={LinkedinIcon}
                    width="25"
                    height="25"
                    alt={"Linkedin logo"}
                  />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.twitter.com/ebrandfi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={TwitterIcon}
                    width="25"
                    height="25"
                    alt={"Twitter logo"}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
)

export default Footer
